import React, { Component } from 'react';
import { Image } from 'semantic-ui-react'

class ImageImersia extends Component
{

	constructor (props)
	{
		super (props);
	}

	render ()
	{
		let imageurl = this.props.src;
		if ((imageurl != "") && (imageurl != "undefined"))
		{
			if (imageurl.indexOf("?") == -1)
			{
				imageurl = imageurl + "?"
			}
			else
			{
				imageurl = imageurl + "&"
			}
			imageurl = imageurl + "sessionid=" + this.props.imersiasdk.sessionid + "&random=" + Math.random();
		}

		return (<Image
			src={((imageurl == undefined) || (imageurl == "")) ? this.props.default : imageurl}
			wrapped={this.props.wrapped}
			fluid={this.props.fluid}
			size={this.props.size}
			centered={this.props.centered}
		/>)
	}
}

export default ImageImersia;
