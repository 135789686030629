// --------------------------------------------------------------------------------------------------------
// Copyright 2019 Imersia Ltd, Author Dr. Roy C. Davies (roy@imersia.com)
// --------------------------------------------------------------------------------------------------------
// Draw a lot of Geobot cards
// --------------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import { Card } from 'semantic-ui-react'

import GeobotCard from './GeobotCard.js';

// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
class Geobots extends Component
{
	// ----------------------------------------------------------------------------------------------------
	// Set up the object
	// ----------------------------------------------------------------------------------------------------
	constructor (props)
	{
		super (props);

		this.state = {
		};

		this.data = {
			currentgeobot : null
		}
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	componentDidMount = () =>
	{
	}

	componentWillUnmount = () =>
	{
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Pass back up to the channel which geobot was clicked on
	// ----------------------------------------------------------------------------------------------------
	closeGeobot = () =>
	{
		this.props.closeGeobot();
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// The render loop
	// ----------------------------------------------------------------------------------------------------
	render ()
	{
		if (this.props.geobots)
		{
			return (
				<Card.Group centered itemsPerRow={3} stackable doubling>
					{this.props.geobots.map(geobot =>
						<GeobotCard
							imersiasdk={this.props.imersiasdk}
							geobotdetails={geobot.properties}
							closeGeobot={this.closeGeobot}/>
						)
					}
				</Card.Group>
			)
		}
		else
		{
			return (<div></div>);
		}
	}
};
// --------------------------------------------------------------------------------------------------------

export default Geobots;
