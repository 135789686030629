// --------------------------------------------------------------------------------------------------------
// Copyright 2019 Imersia Ltd, Author Dr. Roy C. Davies (roy@imersia.com)
// --------------------------------------------------------------------------------------------------------
// Draws a Geobot card given the details
// --------------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import { Card, Image, Button } from 'semantic-ui-react';
import ReactAudioPlayer from 'react-audio-player';
import ImageImersia from './ImageImersia.js';

import noimage from './images/noimage.png';

// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
class GeobotCard extends Component
{
	// ----------------------------------------------------------------------------------------------------
	// Set up the object
	// ----------------------------------------------------------------------------------------------------
	constructor (props)
	{
		super (props);

		this.audioplayer = null;

		this.state = {
			geobotdetails: this.props.geobotdetails,
			audiodetails: this.get_metadata_by_key(this.props.geobotdetails.metadata, "com.imersia.audio"),
			linkdetails: null,
			currentcommand : ""
		};
	}
	// ----------------------------------------------------------------------------------------------------


	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	componentDidMount = () => {
	}
	componentWillUnmount = () => {
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	sendEvent = (e, details) =>
	{
		this.setState({currentcommand: details.command});
		let headers = {
			geobotid: details.geobotid
		}
		let body = {
			event: details.command,
			parameters: details.parameters
		};
		this.props.imersiasdk.POSTHTTP("geobots/send", headers, body);
		setTimeout( () => {
			this.setState({currentcommand: ""});
		}, 1000);
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	openLink = (e, details) =>
	{
		window.open(details.url);
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	isSendingEvent = (command) =>
	{
		return (this.state.currentcommand === command);
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	commandButtons = (commands) =>
	{
		return (
			commands.map(
				command =>
				<Card.Content extra>
					<Button
						fluid basic
						geobotid={this.state.geobotdetails.geobotid}
						command={command}
						parameters={{}}
						loading={this.isSendingEvent(command)}
						onClick={this.sendEvent}>
							{command}
					</Button>
				</Card.Content>
			)
		);
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	linkButtons = (links) =>
	{
		if (links)
		{
			return (
				links.map(
					link =>
					<Card.Content extra>
						<Button
							fluid basic
							url={link.url}
							parameters={{}}
							loading={this.isSendingEvent(link)}
							onClick={this.openLink}>
								{link.name}
						</Button>
					</Card.Content>
				)
			);
		} else {
			return (
				<></>
			)
		}
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	audioPlayer = () => {
		if (this.state.audiodetails)
		{
			return (
				<ReactAudioPlayer
					src={this.state.audiodetails.audiourl}
					loop={this.state.audiodetails.repeat}
					autoPlay={this.state.audiodetails.playonstart}
					volume={this.state.audiodetails.volume}
					ref={(element) => { this.audioplayer = element; }}
				/>
			)
		}
		else {
			return (
				<> </>
			)
		}
	}
	// ----------------------------------------------------------------------------------------------------



	get_metadata_by_key = (metadata, key) => {
		let returnvalue = null;
		metadata.forEach((metadata_entry) => {
			if (metadata_entry.key === key)
			{
				returnvalue = metadata_entry.value;
			}
		});
		return (returnvalue);
	}




	// ----------------------------------------------------------------------------------------------------
	// The render loop
	// ----------------------------------------------------------------------------------------------------
	render ()
	{
		return (
			<Card style={{minHeight: "300px"}}>
				<Button
					compact basic
					geobotid={this.state.geobotdetails.geobotid}
					loading={this.isSendingEvent("touch")}
					command="touch"
					parameters={{}}
					onClick={this.sendEvent}
				>
				<ImageImersia src={this.state.geobotdetails.imageurl} default={noimage} imersiasdk={this.props.imersiasdk} fluid/>
				</Button>
				{this.audioPlayer ()}
                <Card.Content textAlign="center">
                    <Card.Header>{this.state.geobotdetails.name}</Card.Header>
					<Card.Description>{this.state.geobotdetails.description}</Card.Description>
                </Card.Content>
				{this.commandButtons(this.state.geobotdetails.commands)}
				{this.linkButtons(this.get_metadata_by_key(this.state.geobotdetails.metadata, "com.imersia.links"))}
            </Card>
		)
	}
};
// --------------------------------------------------------------------------------------------------------

export default GeobotCard;
