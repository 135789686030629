// --------------------------------------------------------------------------------------------------------
// Copyright 2019 Imersia Ltd, Author Dr. Roy C. Davies (roy@imersia.com)
// --------------------------------------------------------------------------------------------------------
// The main screen after logging in
// --------------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import { Icon, Header, Button, Card, Container, Menu } from 'semantic-ui-react'

import Channels from './Channels.js';
import Geobots from './Geobots.js';


// --------------------------------------------------------------------------------------------------------
// The main class
// --------------------------------------------------------------------------------------------------------
class UserContentViewer extends Component {
    // ----------------------------------------------------------------------------------------------------
    // Set up the object
    // props expected are sessionid and userid
    // ----------------------------------------------------------------------------------------------------
    constructor (props)
    {
        super (props);

        this.state = {
            currentstate: "none"
        }

        this.data = {
            currentchannel: {},
            currentgeobot: {},
            channels: [],
            geobots: []
        };
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // What to start with when the page loads
    // ----------------------------------------------------------------------------------------------------
	componentDidMount = () =>
    {
        this.loadChannels();
    }
    componentWillUnmount = () =>
    {
        this.closeChannels ();
    }
	// ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    closeChannels = () =>
    {
        this.data.channels.forEach((channel) => {
            // this.props.imersiasdk.CLOSE (channel.channelid);
        });
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // Load each channel's details and set up the wotcha events
    // ----------------------------------------------------------------------------------------------------
    loadChannels = () =>
    {
        this.props.imersiasdk.GEOJSON("")
        .then ((resp) => {
            this.data.channels = resp.json.channels;
            let loadingChannelFromQuery = false;
            this.data.channels.forEach((channel) => {
                if (channel.channelid === this.props.entityid)
                {
                    loadingChannelFromQuery = true;
                    this.data.currentchannel = channel;
                }
            });
            if (loadingChannelFromQuery)
            {
                this.loadGeobots();
            }
            else {
                this.setState({currentstate:"reload_channels"});
            }
        })
        .catch((err) => {
            // Do nothing
        });
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    loadGeobots = () =>
    {
        this.props.imersiasdk.GEOJSON(this.data.currentchannel.name)
        .then ((resp) => {
            this.data.geobots = resp.json.features;
            this.setState({currentstate: "reload_geobots"});
        })
        .catch((err) => {
            // Do nothing
        });
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // where to go back to with the back button
    // ----------------------------------------------------------------------------------------------------
    goback = () =>
    {
        switch (this.state.currentstate)
        {
            case "geobots":
                this.closeChannels ();
                this.loadChannels ();
            break;
            default:
            break;
        }
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // Open a list of geobots from a list of channels
    // ----------------------------------------------------------------------------------------------------
    openChannel = (chosenchannel) =>
    {
        this.props.imersiasdk.GEOJSON(chosenchannel.name)
        .then ((resp) => {
            this.data.currentchannel = chosenchannel;
            this.data.geobots = resp.json.features;
            this.setState({currentstate: "reload_geobots"});
        })
        .catch((err) => {
            // Do nothing
        });
    }
    closeChannel = () =>
    {
        this.closeChannels ();
        this.loadChannels ();
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // Show a set of Geobots on a Channel
    // ----------------------------------------------------------------------------------------------------
    channelsContent = () =>
    {
        return (
            <Container fluid>
                <Menu>
                    <Menu.Item>
                        <Icon link large name='home' />
                    </Menu.Item>
                    <Button.Group floated='right'>
                    </Button.Group>
                </Menu>
                <Channels
                    imersiasdk={this.props.imersiasdk}
                    channels={this.data.channels}
                    openChannel={this.openChannel}
                    closeChannel={this.closeChannel}
                />
            </Container>
        )
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // Open a Geobot when clicked on in the GeobotsContent set
    // ----------------------------------------------------------------------------------------------------
    closeGeobot = () =>
    {
        this.loadGeobots ();
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // Show a set of Geobots either in the list or on the map
    // ----------------------------------------------------------------------------------------------------
    geobotsContent = () =>
    {
        return (
            <Container fluid>
                <Menu>
                    <Menu.Item>
                        <Icon link large name='arrow left' onClick={this.goback} />
                    </Menu.Item>
                    <Menu.Item>
                        {this.data.currentchannel.name}
                    </Menu.Item>
                </Menu>
                <Geobots
                    imersiasdk={this.props.imersiasdk}
                    geobots={this.data.geobots}
                    entityid={this.props.entityid}
                    closeGeobot={this.closeGeobot}
                />
            </Container>
        )
    }
    // ----------------------------------------------------------------------------------------------------




    // ----------------------------------------------------------------------------------------------------
    // Makes sure the content tab is rendering the correct output depending on the current state
    // ----------------------------------------------------------------------------------------------------
    showCorrectContent = () =>
    {
        let returnvalue = "";
        switch (this.state.currentstate)
        {
            case "channels":
                returnvalue = (this.channelsContent());
            break;
            case "reload_channels":
                returnvalue = (<div></div>);
                this.setState({currentstate: "channels"});
            break;
            case "geobots":
                returnvalue = (this.geobotsContent());
            break;
            case "reload_geobots":
                returnvalue = (<div></div>);
                this.setState({currentstate: "geobots"});
            break;
            default:
            break;
        }
        return returnvalue;
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // The main render loop
    // ----------------------------------------------------------------------------------------------------
    render ()
    {
        return (
            <>
                {this.showCorrectContent()}
            </>
        );
    }
};
// --------------------------------------------------------------------------------------------------------


export default UserContentViewer;
