// --------------------------------------------------------------------------------------------------------
// Copyright 2019 Imersia Ltd, Author Dr. Roy C. Davies (roy@imersia.com)
// --------------------------------------------------------------------------------------------------------
// Draw a lot of channel cards
// --------------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import { Card } from 'semantic-ui-react'

import ChannelCard from './ChannelCard.js';

// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
class Channels extends Component
{
	// ----------------------------------------------------------------------------------------------------
	// Set up the object
	// ----------------------------------------------------------------------------------------------------
	constructor (props)
	{
		super (props);

		this.state = {
		};
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	componentDidMount = () =>
	{
	}

	componentWillUnmount = () =>
	{
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Pass back up to the user content viewer list which channel was clicked on
	// ----------------------------------------------------------------------------------------------------
	openChannel = (chosenchannel) =>
	{
		this.props.openChannel(chosenchannel);
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// The render loop
	// ----------------------------------------------------------------------------------------------------
	render ()
	{
		if (this.props.channels)
		{
			return (
				<Card.Group centered itemsPerRow={3} stackable doubling>
					{this.props.channels.map(channel =>
						<ChannelCard
							imersiasdk={this.props.imersiasdk}
							channeldetails={channel}
							openChannel={this.openChannel}
							closeChannel={this.closeChannel}
							/>
						)}
				</Card.Group>
			)
		}
		else {
			return (<div></div>)
		}
	}
};
// --------------------------------------------------------------------------------------------------------

export default Channels;
