// --------------------------------------------------------------------------------------------------------
// Copyright 2019 Imersia Ltd, Author Dr. Roy C. Davies (roy@imersia.com)
// --------------------------------------------------------------------------------------------------------
// SDK for accessing the Imersia API, and other hardware routines, such as location monitoring
// --------------------------------------------------------------------------------------------------------
import geohash from 'geohash';


class ImersiaSDK
{
	static OK = 0;
	static ERROR = -1;


	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	constructor ()
	{
		this.loggingin = false;
		this.sessionid = null;
		this.userid = null;
		this.useremail = null;
		this.maptoken = null;
		this.version = null;
		this.isadmin = false;
		this.headers = {
            developerid: "com.imersia.portal",
            location: ""
        };
		this.websocket = null;
		this.connected = false;

		this.latitude = 0;
		this.longitude = 180;
		this.altitude = 0;
		this.heading = 0;
		this.accuracy = 0;
		this.altitudeAccuracy = 0;
		this.speed = 0;

		this.mapstore = {
			center: [180, 0],
			zoom: 3
		};

		this.options = {
			enableHighAccuracy: true,
			timeout: 10000,
			maximumAge: 0
		};

		this.startLocationMonitoring();
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	startLocationMonitoring = () =>
	{
	    // Setup the values required to calculate the Magnetic Declination
	    // var cof = syncXHR('../geomagjs/WMM.COF');
	    // var newGeomag = new Geomag(cof);
	    // self.geoMag = newGeomag.mag;

	    if (navigator.geolocation) {
	        navigator.geolocation.watchPosition((position) => {
		            this.latitude = position.coords.latitude;
		            this.longitude = position.coords.longitude;
		            this.altitude = position.coords.altitude;
		            this.accuracy = position.coords.accuracy;
		            this.altitudeAccuracy = position.coords.altitudeAccuracy;
		            this.speed = position.coords.speed;
		            this.headers.location = geohash.GeoHash.encodeGeoHash(position.coords.latitude, position.coords.longitude);
					this.sendEvent();

		        }, (err) => {
					console.warn('GPS ERROR(' + err.code + '): ' + err.message);
				},
				this.options
			);
	    }
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	sendEvent = () => {
		var event = new CustomEvent("updategps", {detail: {location: this.headers.location, sessionid:this.sessionid, userid: this.userid, connected:this.connected}});
		document.dispatchEvent(event);
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Get something from the API
	// ----------------------------------------------------------------------------------------------------
	GET = (command, parameters) =>
	{
		return new Promise ( (resolve, reject) =>
		{
			let status = 0;
			let headers = parameters;
			if (this.sessionid) headers.sessionid = this.sessionid;
			if (!headers.location) headers.location = this.headers.location;
			headers.developerid = this.headers.developerid;

			// Get the item requested
			fetch("https://" + window.location.host + "/api/" + command, { method:"GET", headers:headers })
			.then ((resp) => {
				status = resp.status;
				return (resp.json());
			})
			.then((resp) => {
				if (status === 200 || status === 204)
				{
					resolve ( {"result": ImersiaSDK.OK, "status": status, "json": resp} );
				}
				else
				{
					reject ( {"result": ImersiaSDK.ERROR, "status": status, "json": resp} );
				}
			})
			.catch ((err) => {
				reject ( {"result": ImersiaSDK.ERROR, "status": 500, "json": {"error": "get"} } );
			});
		});
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Get GeoJSON from the API
	// ----------------------------------------------------------------------------------------------------
	GEOJSON = (channelname) =>
	{
		return new Promise ( (resolve, reject) =>
		{
			let status = 0;
			let headers = {
				location: this.headers.location
			};

			fetch("https://" + window.location.host + "/geojson/" + channelname, { method:"GET", headers:headers })
			.then ((resp) => {
				status = resp.status;
				return (resp.json());
			})
			.then((resp) => {
				if (status === 200 || status === 204)
				{
					resolve ( {"result": ImersiaSDK.OK, "status": status, "json": resp} );
				}
				else
				{
					reject ( {"result": ImersiaSDK.ERROR, "status": status, "json": resp} );
				}
			})
			.catch ((err) => {
				reject ( {"result": ImersiaSDK.ERROR, "status": 500, "json": {"error": "geojson"} } );
			});
		});
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Test the existance of something (put either userid, channelid, geobotid, metadataid or automationid)
	// along with the appropriate command (user, channels, geobots, metadata, geobots/automations)
	// With metadata include the entityid (userid, channelid, geobotid) as well as metadataid or key
	// With automations include the geobotid as well as the automationid
	// ----------------------------------------------------------------------------------------------------
	HEAD = (command, parameters) =>
	{
		return new Promise ( (resolve, reject) =>
		{
			let headers = parameters;
			if (this.sessionid) headers.sessionid = this.sessionid;
			if (!headers.location) headers.location = this.headers.location;
			headers.developerid = this.headers.developerid;

			fetch("https://" + window.location.host + "/api/" + command, {
				method:"HEAD", headers:headers
			})
			.then ((resp) => {
				if (resp.status === 200 || resp.status === 204)
				{
					resolve ({"result": ImersiaSDK.OK, "status": resp.status});
				}
				else {
					reject ({"result":ImersiaSDK.ERROR, "status": resp.status})
				}
			})
			.catch ((err) => {
				reject ( {"result":ImersiaSDK.ERROR, "status": 500} );
			});
		});
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Put something to the API
	// ----------------------------------------------------------------------------------------------------
	PUT = (command, parameters, body) =>
	{
		return new Promise ( (resolve, reject) =>
		{
			let status = 0;
			let headers = parameters;
			if (this.sessionid) headers.sessionid = this.sessionid;
			if (!headers.location) headers.location = this.headers.location;
			headers.developerid = this.headers.developerid;
			headers["Content-type"] = "application/json; charset=utf-8";

			fetch("https://" + window.location.host + "/api/" + command, {
				method:"PUT", headers:headers, body:JSON.stringify(body)
			})
			.then ((resp) => {
				status = resp.status;
				return (resp.json());
			})
			.then((resp) => {
				if (status === 200 || status === 204)
				{
					resolve ( {"result": ImersiaSDK.OK, "status": status, "json": resp} );
				}
				else
				{
					reject ( {"result": ImersiaSDK.ERROR, "status": status, "json": resp} );
				}
			})
			.catch ((err) => {
				reject ( {"result": ImersiaSDK.ERROR, "status": 500, "json": {"error": "put"} } );
			});
		});
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Post something to the API
	// ----------------------------------------------------------------------------------------------------
	POST = (command, parameters, body) =>
	{
		return new Promise ( (resolve, reject) =>
		{
			let status = 0;
			let headers = parameters;
			if (this.sessionid) headers.sessionid = this.sessionid;
			if (!headers.location) headers.location = this.headers.location;
			headers.developerid = this.headers.developerid;
			headers["Content-type"] = "application/json; charset=utf-8";

			fetch("https://" + window.location.host + "/api/" + command, {
				method:"POST", headers:headers, body:JSON.stringify(body)
			})
			.then ((resp) => {
				status = resp.status;
				return (resp.json());
			})
			.then((resp) => {
				if (status === 200 || status === 204)
				{
					resolve ( {"result": ImersiaSDK.OK, "status": status, "json": resp} );
				}
				else
				{
					reject ( {"result": ImersiaSDK.ERROR, "status": status, "json": resp} );
				}
			})
			.catch ((err) => {
				reject ( {"result": ImersiaSDK.ERROR, "status": 500, "json": {"error": "post"} } );
			});
		});
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Delete something from the API
	// ----------------------------------------------------------------------------------------------------
	DELETE = (command, parameters) =>
	{
		return new Promise ( (resolve, reject) =>
		{
			let status = 0;
			let headers = parameters;
			if (this.sessionid) headers.sessionid = this.sessionid;
			if (!headers.location) headers.location = this.headers.location;
			headers.developerid = this.headers.developerid;

			fetch("https://" + window.location.host + "/api/" + command, {
				method:"DELETE", headers:headers
			})
			.then ((resp) => {
				status = resp.status;
				return (resp.json());
			})
			.then((resp) => {
				if (status === 200 || status === 204)
				{
					resolve ( {"result": ImersiaSDK.OK, "status": status, "json": resp} );
				}
				else
				{
					reject ( {"result": ImersiaSDK.ERROR, "status": status, "json": resp} );
				}
			})
			.catch ((err) => {
				reject ( {"result": ImersiaSDK.ERROR, "status": 500, "json": {"error": "delete"} } );
			});
		});
	}
	// ----------------------------------------------------------------------------------------------------




	// ----------------------------------------------------------------------------------------------------
	// Post one or more files to the given entity (either userid, channelid or geobotid in the parameters)
	// ----------------------------------------------------------------------------------------------------
	POSTFILES = (parameters, files) =>
	{
		return new Promise ( (resolve, reject) =>
		{
			let status = 0;
			let headers = parameters;
			if (this.sessionid) headers.sessionid = this.sessionid;
			if (!headers.location) headers.location = this.headers.location;
			headers.developerid = this.headers.developerid;

			const formData = new FormData();
			files.forEach((file) => {
				formData.append (file.name, file);
			});

			fetch("https://" + window.location.host + "/api/files", {method:"POST", headers:headers, body:formData})
			.then ((resp) => {
				status = resp.status;
				return (resp.json());
			})
			.then((resp) => {
				if (status === 200 || status === 204)
				{
					resolve ( {"result": ImersiaSDK.OK, "status": status, "json": resp} );
				}
				else
				{
					reject ( {"result": ImersiaSDK.ERROR, "status": status, "json": resp} );
				}
			})
			.catch ((err) => {
				reject ( {"result": ImersiaSDK.ERROR, "status": 500, "json": {"error": "postfiles"} } );
			});
		});
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Perform a login, get the userid, and open the websocket
	// ----------------------------------------------------------------------------------------------------
	LOGIN = (useremail, password) =>
	{
		this.loggingin = true;
		return new Promise ( (resolve, reject) =>
		{
			this.GET ("sessions", {useremail:useremail, password:password})
			.then ((resp) => {
				this.sessionid = resp.json.sessionid;
				this.useremail = useremail;

				return (this.GET("user", {useremail:useremail}));
			})
			.then ((resp) => {
				this.userid = resp.json.userid;

				// sendEvent();
				// var event = new CustomEvent("updategps", {location: this.headers.location, sessionid:this.sessionid, userid: this.userid});
				// document.dispatchEvent(event);

				this.CONNECT(this.userid);
				this.loggingin = false;
				return (this.GET('vars', {category: "maptoken", key : "mapbox"}));
			})
			.then((resp) => {
				this.maptoken = resp.json.value;
				return (this.GET('vars', {category: "mrserver", key : "version"}));
			})
			.then ((resp) => {
				this.version = resp.json.value;
				return new Promise ((resolve, reject) => {
					this.GET("admin", {"command":"stats", "parameters":"{}"})
					.then((resp) => {
						resolve (true);
					})
					.catch((err) => {
						resolve (false);
					});
				});
			})
			.then ((resp) => {
				this.isadmin = resp;
				resolve ({loggedin:"ok"});
			})
			.catch ((err) => {
				this.loggingin = false;
				this.sessionid = null;
				this.useremail = null;
				this.userid = null;
				this.maptoken = null;
				this.isadmin = false;
				this.version = null;
				reject (err);
			});
		});
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	loggedIn = () =>
	{
		return ((this.sessionid != null) && (this.userid != null))
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	loggingIn = () =>
	{
		return (this.loggingin)
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
    // Connect to Websocket
    // ----------------------------------------------------------------------------------------------------
    CONNECT = (userid) =>
    {
		if (userid)
		{
			if (this.websocket)
			{
				this.connected = true;
				console.log("WEBSOCKET ALREADY OPEN");
				this.sendEvent();
			}
			else {
				if(!(("WebSocket" in window) || ("MozWebSocket" in window)))
				{
					// Error message - cant use websocket
					console.log("CANNOT OPEN WEBSOCKET");
				}
				else
				{
					// Connect to the Companion via Secure Websocket to monitor changes and messages
					var ws = window.WebSocket || window.MozWebSocket;
					this.websocket = new ws("wss://" + window.location.host + "/wotcha/" + userid);
					this.websocket.onopen = (evt) => {
						// Success Message
						this.connected = true;
						console.log("WEBSOCKET OPEN");
						this.sendEvent();
					};
					this.websocket.onclose = (evt) => {
						// Close Websocket
						this.connected = false;
						console.log("WEBSOCKET CLOSED");
						this.sendEvent();
					};
					this.websocket.onmessage = (evt) => {
						// handle message arriving
						this.processWebsocketMessage(evt);
					};
					this.websocket.onerror = (evt) => {
						// Error message
						this.connected = false;
						console.log("WEBSOCKET ERROR");
						this.sendEvent();
					};
				}
			}
		}
    }
    // ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	OPEN = (id) =>
	{
		if (this.websocket)
		{
			this.websocket.send(JSON.stringify({ command : 'wotcha', sessionid : this.sessionid, parameters : { id : id } }));
		}
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	CLOSE = (id) =>
	{
		if (this.websocket)
		{
			this.websocket.send(JSON.stringify({ command : 'close', sessionid : this.sessionid, parameters : { id : id } }));
		}
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    SEND = (id, message, parameters) =>
    {
        parameters = parameters || {};

        if (message !== "")
        {
            if (this.websocket)
            {
                if (this.websocket.readyState === this.websocket.OPEN)
                {
                    var txtjson = {
                        command : 'event',
                        sessionid : this.sessionid,
                        parameters : {
                            id : id,
                            event : message,
                            parameters : parameters
                        }
                    };
                    this.websocket.send(JSON.stringify(txtjson));
                }
                else
                {
                    // Not connected error message
                }
            }
            else
            {
                // Not connected error message
            }
        }
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
	sendCompanionMessage = (wotchaevent, message) =>
	{
		var event = new CustomEvent(this.userid, {detail:message});
        document.dispatchEvent(event);
	}
    sendChannelMessage = (wotchaevent, message) =>
    {
        var channelid = message.wotcha[wotchaevent].channelid;
        var event = new CustomEvent(channelid, {detail:message});
        document.dispatchEvent(event);
    }

	sendNewChannelMessage = (message) =>
	{
		var event = new CustomEvent("channel_new", {detail:message});
		document.dispatchEvent(event);
	}

	sendGeobotMessage = (wotchaevent, message) =>
    {
        var geobotid = message.wotcha[wotchaevent].geobotid;
        var event = new CustomEvent(geobotid, {detail:message});
        document.dispatchEvent(event);
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    processWebsocketMessage = (evt) =>
    {
		if (this.websocket)
		{
			if (evt.data === 'ping')
			{
				this.websocket.send('pong');
			}
			else
			{
				var message = JSON.parse(evt.data);
				console.log(message);
				if (message.hasOwnProperty("wotcha"))
				{
					if (message.wotcha.hasOwnProperty("trigger")) {
						this.sendChannelMessage("trigger", message);
						this.sendGeobotMessage("trigger", message);
					}
					else if (message.wotcha.hasOwnProperty("channel_set")) {
						this.sendChannelMessage("channel_set", message);
					}
					else if (message.wotcha.hasOwnProperty("channel_rename")) {
						this.sendChannelMessage("channel_rename", message);
					}
					else if (message.wotcha.hasOwnProperty("geobot_set")) {
						this.sendChannelMessage("geobot_set", message);
						this.sendGeobotMessage("geobot_set", message);
					}
					else if (message.wotcha.hasOwnProperty("channel_new")) {
						this.sendNewChannelMessage(message);
					}
					else if (message.wotcha.hasOwnProperty("channel_delete")) {
						this.sendChannelMessage("channel_delete", message);
					}
					else if (message.wotcha.hasOwnProperty("geobot_new")) {
						this.sendChannelMessage("geobot_new", message);
					}
					else if (message.wotcha.hasOwnProperty("geobot_delete")) {
						this.sendChannelMessage("geobot_delete", message);
						this.sendGeobotMessage("geobot_delete", message);
					}
					else if (message.wotcha.hasOwnProperty("geobot_automations")) {
						this.sendChannelMessage("geobot_automations", message);
						this.sendGeobotMessage("geobot_automations", message);
					}
					else if (message.wotcha.hasOwnProperty("user_tokens")) {
						this.sendCompanionMessage("user_tokens", message);
					}
					else if (message.wotcha.hasOwnProperty("user_set")) {
						this.sendCompanionMessage("user_set", message);
					}
				}
				else if (message.hasOwnProperty("message"))
				{
				}
			};
		}
	}
    // ----------------------------------------------------------------------------------------------------
}

export default ImersiaSDK;
