import React, { Component } from 'react';
import { } from 'semantic-ui-react'

import logo from './logo.svg';
import './App.css';
import UserContentViewer from './UserContentViewer.js';
import ImersiaSDK from './ImersiaSDK.js';
import MetaTags from 'react-meta-tags';


class App extends Component
{
    // ----------------------------------------------------------------------------------------------------
    // Construct the App object
    // ----------------------------------------------------------------------------------------------------
    constructor (props)
    {
        super (props);

        this.state = {
            entityid : null,
            loaded: false
        };

        this.imersiasdk = new ImersiaSDK();
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    componentDidMount = () =>
    {
        this.getEntityID();
    }
    // ----------------------------------------------------------------------------------------------------




    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    getEntityID = () =>
    {
        let url = new URL(window.location.href);
        let entityid = url.searchParams.get("id");
        this.setState({entityid: entityid, loaded:true});
    }
    // ----------------------------------------------------------------------------------------------------



    // ----------------------------------------------------------------------------------------------------
    // The main render loop
    // ----------------------------------------------------------------------------------------------------
    render ()
    {
        if (this.state.loaded)
        {
            return (
                <>
                    <MetaTags>
                        <meta name = "viewport" content = "initial-scale = 1.0, maximum-scale = 1.0, user-scalable = no, width = device-width"/>
                        <meta name = "apple-mobile-web-app-capable" content = "yes"/>
                        <meta name = "apple-touch-fullscreen" content = "yes"/>
                        <meta name = "mobile-web-app-capable" content = "yes"/>
                        <meta name = "apple-mobile-web-app-status-bar-style" content="black-translucent"/>
                        <meta name = "apple-mobile-web-app-title" content="Imersia WebApp"/>
                    </MetaTags>
                    <UserContentViewer
                        imersiasdk={this.imersiasdk}
                        entityid={this.state.entityid}
                    />
                </>
            );
        }
        else {
            return (
                <>
                </>
            )
        }
    }
}

export default App;
