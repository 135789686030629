// --------------------------------------------------------------------------------------------------------
// Copyright 2019 Imersia Ltd, Author Dr. Roy C. Davies (roy@imersia.com)
// --------------------------------------------------------------------------------------------------------
// Draws a channel card given the details
// --------------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import { Modal, Card, Label, Image, Icon, Menu, Button, Dropdown, Segment} from 'semantic-ui-react';
import Channel from './patterns/Channel.json';
import ImageImersia from './ImageImersia.js';

import noimage from './images/noimage.png';

// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
class ChannelCard extends Component
{
	// ----------------------------------------------------------------------------------------------------
	// Set up the object
	// ----------------------------------------------------------------------------------------------------
	constructor (props)
	{
		super (props);

		this.state = {
			channeldetails: JSON.parse(JSON.stringify(this.props.channeldetails)),
			editedchanneldetails: {},
			channelmetadata:[],
			error: false,
			error_message: ""
		};
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	componentDidMount = () => {
    }
    componentWillUnmount = () => {
    }
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// Pass back up to the channel list which channel was clicked on
	// ----------------------------------------------------------------------------------------------------
	openChannel = () =>
	{
		this.props.openChannel(this.state.channeldetails);
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// ----------------------------------------------------------------------------------------------------
	mainBit = () =>
	{
		const imageurl = this.state.channeldetails.imageurl;

		return (
			<>
				<Button
					basic compact
					onClick={this.openChannel}>
					<ImageImersia src={imageurl} default={noimage} imersiasdk={this.props.imersiasdk} fluid/>
				</Button>
			</>
		)
	}
	// ----------------------------------------------------------------------------------------------------



	// ----------------------------------------------------------------------------------------------------
	// The render loop
	// ----------------------------------------------------------------------------------------------------
	render ()
	{
		return (
			<Card style={{minHeight: "300px"}}>
				{this.mainBit()}
				<Card.Content textAlign="center">
					<Card.Header>{this.state.channeldetails.name}</Card.Header>
					<Card.Description>{this.state.channeldetails.description}</Card.Description>
				</Card.Content>
			</Card>
		)
	}
};
// --------------------------------------------------------------------------------------------------------

export default ChannelCard;
